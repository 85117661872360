<template>
  <div class="search-page">
    <div v-if="!loading" class="content-wrap">
      <div v-if="searchData.length > 0">
        <div class="search-title">搜索结果如下：</div>
        <div class="course-wrap">
          <div class="course-list">
            <div class="course-item" v-for="(item, index) in searchData" :key="index" @click="toNavInfo(item)">
              <img v-if="item.pc_cover1" class="cover" :src="item.pc_cover1 ? item.pc_cover1 : item.front_cover4" />
              <div v-else class="cover">
                <default-cover />
              </div>
              <div class="info-wrap">
                <div class="title">{{ item.name }}</div>
                <div class="info">
                  <span>{{ item.virtually_pay_num !== '0' && item.virtually_pay_num !== '' ? item.virtually_pay_num : item.user_count }}人已报名</span>
                  <p v-if="parseFloat(item.price) > 0" class="price">￥{{ item.price }}</p>
                  <p v-else>免费</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="total > 0" class="page-wrap">
            <el-pagination
                background
                layout="prev, pager, next, jumper"
                :page-size="queryParams.limit"
                :current-page="queryParams.page"
                @current-change="handleCurrentChange"
                :title="pageTitle"
                :total="total">
            </el-pagination>
            <div class="total-wrap">共 {{ total }} 条</div>
          </div>
        </div>
      </div>
      <div v-else class="search-no-data">
        <img src="../assets/img/search_no_data.png" />
        <span>很抱歉，没有搜索到相关内容~</span>
      </div>
    </div>
    <loading :is-load="loading" />
  </div>
</template>

<script>
import * as courseService from '@/service/course-service';
import { Message } from 'element-ui';

export default {
  name: 'search',
  data() {
    return {
      loading: false,
      queryParams: {
        course_type: '1',
        limit: 16,
        page: 1
      },
      total: 0,
      searchData: [],
      keywords: '',
      pageTitle: ''
    };
  },
  mounted() {
    this.keywords = this.$route.query.keywords;
    this.getSearchCourseData();
  },
  methods: {
    /**
     * 获取分类课程列表
     * */
    getSearchCourseData() {
      const data = {
        ...this.queryParams,
        keywords: this.keywords
      };
      this.loading = true;
      courseService.getIndexCategory(data).then(res => {
        this.loading = false;
        if (res.code === 1) {
          this.searchData = res.data.list;
          this.total = res.data.total;
          const pages = Math.ceil(this.total / this.queryParams.limit);
          this.pageTitle = `值必须小于或等于${pages}`;
        }
      });
    },
    /**
     * 切换分页
     * */
    handleCurrentChange(val) {
      const inputVal = parseInt(document.querySelector('.el-pagination__jump .el-input__inner').value);
      const pages = Math.ceil(this.total / this.queryParams.limit);
      if (inputVal > pages) {
        Message({
          message: `值必须小于或等于${pages}`,
          type: 'warning',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
      }
      this.queryParams.page = val;
      this.getSearchCourseData();
    },
    /**
     * 跳转详情
     * course_type 1=普通课程 2=直播课 0=套课
     * */
    toNavInfo(info) {
      const path = info.course_type === '1' ? '/courseDetail' : info.course_type === '2' ? 'liveDetail' : '/packageDetail';
      this.$router.push({
        path: path,
        query: {
          id: info.id
        }
      });
    }
  },
  beforeRouteUpdate(to) {
    this.keywords = to.query.keywords;
    this.getSearchCourseData();
  }
};
</script>

<style lang="scss" scoped>
.search-page {
  .content-wrap {
    display: flex;
    flex-direction: column;
    .search-title {
      font-size: 18px;
      color: #666666;
      line-height: 1;
      margin-bottom: 24px;
      margin-top: 33px;
    }
    .course-list {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      .course-item {
        width: 286px;
        height: 250px;
        background: #FFFFFF;
        border-radius: 8px;
        cursor: pointer;
        overflow: hidden;
        line-height: 1;
        margin: 0 18px 20px 0;
        transition: all .4s;
        .cover {
          width: 286px;
          height: 176px;
        }
        .info-wrap {
          padding: 0 16px 0 15px;
          .title {
            margin: 14px 0 10px;
            font-size: 18px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              font-size: 12px;
              font-weight: 400;
              color: #666666;
            }
            p {
              font-size: 18px;
              color: #E22323;
            }
            .price {
              font-weight: bold;
            }
          }
        }

      }
      .course-item:nth-child(4n) {
        margin-right: 0;
      }
      .course-item:hover {
        box-shadow: 2px 4px 9px 0px rgba(40,105,245,0.09);
        transform: translateY(-5px);
      }
    }
    .page-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 48px;
      margin-bottom: 73px;
      ::v-deep .el-pagination.is-background .btn-next, ::v-deep .el-pagination.is-background .btn-prev, ::v-deep .el-pagination.is-background .el-pager li {
        background-color: #FFFFFF;
      }
      ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #2869F5;
      }
      ::v-deep .el-pagination__jump {
        line-height: 34px;
        height: 34px;
      }
      .total-wrap {
        margin-left: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
    .search-no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px 0 32px;
      height: 560px;
      background: #FFFFFF;
      border-radius: 6px;
      img {
        width: 289px;
        height: 251px;
        margin-top: 96px;
      }
      span {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
</style>
